import { getDictionaryMethod } from 'api/managements/operators';
import { Icon } from 'components/ui/Icon';
import { ManagementPartner } from 'models/Management/Partners/Partner';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { usePermission } from 'utils/usePermission';
import cn from 'classnames';
import styles from './partner-account-status.module.scss';

type Props = {
  partner: ManagementPartner;
  onChangeStatus: (status: string) => void;
};

export const PartnerAccountStatus = ({ partner, onChangeStatus }: Props) => {
  const { permissionGiven: PermissionActivate } = usePermission('admin.partner.active');

  const dropMenu = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [statusList, setStatusList] = useState<string[]>([]);

  const fetchStatusList = () => {
    getDictionaryMethod().then((res) => {
      if (res.status === 200) {
        const arrFilter = res.data.operator_statuses.filter((item) => item !== partner?.status);
        setStatusList(arrFilter);
      }
    });
  };

  useEffect(() => {
    if (partner) {
      fetchStatusList();
    }
  }, [partner]);

  useEffect(() => {
    const onClickOutside = (event) => {
      if (dropMenu.current && !dropMenu.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', onClickOutside, true);

    return () => document.removeEventListener('click', onClickOutside, true);
  }, []);

  const onToggleOpen = () => setIsOpen((p) => !p);

  return (
    <>
      <div className={styles.container}>
        <div className={cn(styles.status, styles[partner.status])}>
          <span>{partner && partner?.status ? partner.status : '---'}</span>
          {PermissionActivate ? (
            <Icon
              name="caretDown"
              size={24}
              className={cn(isOpen && styles.active)}
              onClick={onToggleOpen}
            />
          ) : null}
        </div>
        {partner?.status_updated_at && (
          <span>
            since {moment(partner.status_updated_at * 1000).format('DD.MM.YYYY HH:mm:ss')}
          </span>
        )}
        <div ref={dropMenu} className={cn(styles.dropdown, isOpen && styles.dropdown__open)}>
          <ul>
            {statusList.map((status, index) => {
              return (
                <li
                  key={index}
                  className={status === 'active' ? 'unblock' : 'blocked'}
                  onClick={() => {
                    setIsOpen(false);
                    onChangeStatus(status);
                  }}
                >
                  {status}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};
