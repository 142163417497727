import { copyToBuffer } from 'utils/copyToBuffer';
import { getShortUuid } from 'utils/getShortUuid';

import styles from './note.module.scss';

type Props = {
  uuid?: string;
};

export const NoteUuid = ({ uuid = '' }: Props) => {
  return (
    <div className={styles.uuid}>
      {uuid ? (
        <span className="copuToBuffer" onClick={() => copyToBuffer(uuid, true)}>
          {getShortUuid(uuid)}
        </span>
      ) : (
        '---'
      )}
    </div>
  );
};
