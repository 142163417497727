import { Note } from 'components/ui/Notes';
import { NotesItemActions } from './NotesItemActions';

export const NotesItem = ({ noteItem, updateNotesList }) => {
  return (
    <Note
      note={noteItem}
      actions={<NotesItemActions note={noteItem} updateNotesList={updateNotesList} />}
    />
  );
};
