import moment from 'moment';
import { copyToBuffer } from 'utils/copyToBuffer';
import { getShortUuid } from 'utils/getShortUuid';

import styles from './note.module.scss';

type Props = {
  createdAt: number;
  to: string;
};

export const NoteDate = ({ createdAt, to }: Props) => {
  return (
    <div className={styles.date}>
      {moment(createdAt * 1000).format('DD.MM.YYYY HH:mm:ss')} to{' '}
      <span className="copuToBuffer" onClick={() => copyToBuffer(to, true)}>
        {getShortUuid(to)}
      </span>
    </div>
  );
};
