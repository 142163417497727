import avatarMan from 'assets/img/avatars/avatarMan.png';
import avatarManBeard from 'assets/img/avatars/avatarManBeard.png';
import avatarManGlasses from 'assets/img/avatars/avatarManGlasses.png';
import avatarManJedi from 'assets/img/avatars/avatarManJedi.png';
import avatarManOffice from 'assets/img/avatars/avatarManOffice.png';
import avatarWoman from 'assets/img/avatars/avatarWoman.png';
import avatarWomanGlasses from 'assets/img/avatars/avatarWomanGlasses.png';

export const avatars = [
  { src: avatarMan, id: 'avatar_man' as const },
  { src: avatarManBeard, id: 'avatar_man_beard' as const },
  { src: avatarManGlasses, id: 'avatar_man_glass' as const },
  { src: avatarManJedi, id: 'avatar_man_jedi' as const },
  { src: avatarManOffice, id: 'avatar_man_office' as const },
  { src: avatarWoman, id: 'avatar_woman' as const },
  { src: avatarWomanGlasses, id: 'avatar_woman_glasses' as const },
];
