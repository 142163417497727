import styles from './pinned-note.module.scss';

type Props = {
  operator?: any;
};

const getOperatorName = (operator?: any) => {
  if (!operator) {
    return <span className={styles.name__deleted}>Deleted operator</span>;
  }

  const { first_name = '', last_name = '' } = operator;

  return <span className={styles.name}>{`${first_name} ${last_name}`}</span>;
};

export const PinnedNoteName = ({ operator }: Props) => {
  return (
    <div>
      <span className={styles.pre}>by </span>
      {getOperatorName(operator)}
    </div>
  );
};
