import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { logOut, signIn } from '../../api/auth';
import { notify } from '../../utils/notify';
import { DisconnectWSS } from '../../utils/ConnectWSS';
import { RootState } from 'store';

const token = sessionStorage.getItem('token') || localStorage.getItem('token');

export const logout = createAsyncThunk('auth/logout', async (_, { rejectWithValue }) => {
  try {
    const response = await logOut();
    DisconnectWSS();
    const theme = localStorage.getItem('theme');
    const avatar = localStorage.getItem('avatar');
    // localStorage.removeItem('token');
    localStorage.clear();
    // window.location.replace('/');
    if (theme) {
      localStorage.setItem('theme', theme);
    }

    if (avatar) {
      localStorage.setItem('avatar', avatar);
    }
    // return response
  } catch (error) {
    if (
      error.message === 'Request failed with status code 500' ||
      'Request failed with status code 403'
    ) {
      localStorage.removeItem('token');
      window.location.reload();
    }
    notify({
      type: 'error',
      message: error.response.data.message,
      timeOut: 3000,
    });
    return rejectWithValue(error.response.data.message);
  }
});

type LoginInput = {
  email: string;
  password: string;
};

export const login = createAsyncThunk<any, LoginInput>(
  'auth/login',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await signIn(creds);
      if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('role', JSON.stringify(response.data.role));
        localStorage.setItem('permissions', JSON.stringify(response.data.permissions));
        localStorage.setItem('departments', JSON.stringify(response.data.departments));
        localStorage.setItem('user_id', JSON.stringify(response.data.user_id));
      }

      return response.data.token;
    } catch (error) {
      notify({
        type: 'error',
        message: error.response.data.message,
        timeOut: 3000,
      });
      return rejectWithValue(error.response.data.message);
    }
  },
);

const initialState = {
  token: null,
  isAuthorized: !!token,
  isLoading: false,
  isError: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //login
    builder.addCase(login.pending, (state) => {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    });
    builder.addCase(login.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        isAuthorized: true,
        token: action.payload,
      };
    });
    builder.addCase(login.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };
    });

    //logout
    builder.addCase(logout.pending, () => initialState);
    builder.addCase(logout.fulfilled, (state) => {
      return {
        ...state,
        isAuthorized: false,
      };
    });
    builder.addCase(logout.rejected, () => initialState);
  },
});

const { reducer } = authSlice;
export default reducer;

export const selectAuth = (state) => state.auth;
export const selectAuthIsLoading = (state: RootState) => state.auth.isLoading;
export const selectAuthIsAuthorized = (state: RootState) => state.auth.isAuthorized;
