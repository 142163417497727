import { getCountryList } from 'api/country';
import { getLeadInfo } from 'api/leads';
import { fetchPinnedNotesList } from 'api/notes';
import Page from 'pages/Page/Page';
import { DashboardLayout } from 'pages/UserView/DashboardLayout';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { usePermission } from '../../../utils/usePermission.tsx';
import styles from './lead-page.module.scss';
import LeadAdressForm from './LeadForms/LeadAdressForm';
import LeadContactForm from './LeadForms/LeadContactForm';
import LeadPersonalInfoForm from './LeadForms/LeadPersonalInfoForm';
import Feed from './LeadTabs/Feed/Feed';
import NotesTabs from './LeadTabs/NotesTabs/NotesTabs';
import { TableLinks, TableMeta } from 'models/Table';
import { Lead } from 'models/Leads/Lead';
import { ICountry } from 'models/Countries';
import { LeadNote } from 'models/Leads/LeadNotes';
import { usePageTitle } from 'utils/usePageTitle.ts';
import { LeadPageHeader } from './LeadPageHeader.tsx';
import { LeadInfo } from './LeadInfo/LeadInfo.tsx';

const LeadPage = () => {
  const { permissionGiven: PermissionShowLead } = usePermission('admin.lead.show', true);
  const { permissionGiven: PermissionViewDocumentLogs } = usePermission('admin.logs.document');
  const { permissionGiven: PermissionShowPersonalInfo } = usePermission(
    'admin.lead.show-personal-info',
  );
  const { permissionGiven: PermissionShowAddress } = usePermission('admin.lead.show-address');
  const { permissionGiven: PermissionShowContacts } = usePermission('admin.lead.show-contacts');

  const params = useParams();

  const [clientName, setClientName] = useState('');
  const [clientData, setClientData] = useState<Lead>();
  const [isListLoading, setIsListLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [countryListArr, setCountryListArr] = useState<ICountry[]>([]);

  const [pinnedNotesList, setPinnedNotesList] = useState<LeadNote[]>([]);
  const [notesLoading, setNotesLoading] = useState(true);

  const [meta, setMeta] = useState<TableMeta>();
  const [links, setLinks] = useState<TableLinks>();

  const title = 'Lead - ' + clientName;

  const componentMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (PermissionShowLead) {
      getCountryList().then((res) => setCountryListArr(res.data.data));
    }
  }, [PermissionShowLead]);

  const getClient = (params) => {
    setIsListLoading(true);

    getLeadInfo(params.id)
      .then((res) => {
        setClientData(res.data.data);
        setIsLoading(false);
        setClientName(res.data.data.name + ' ' + res.data.data.surname);
      })
      .catch((err) => {
        setIsListLoading(false);
      });
  };

  const getPinnedNotes = (params?, isNextCall?) => {
    setNotesLoading(true);

    let searchParams: string | number = '';
    if (isNextCall) {
      let arr = links.next.split('=');
      searchParams = links && links.next ? links.next.split('=')[arr.length - 1] : 1;
    }
    fetchPinnedNotesList(params.id, {
      page: links?.next ? searchParams : 1,
      is_pinned: 1,
      per_page: 10,
    })
      .then((res) => {
        if (isNextCall) {
          setPinnedNotesList((prev) => prev.concat(res.data.data));
        } else {
          setPinnedNotesList(res.data.data);
        }
        setMeta(res.data.meta);
        setLinks(res.data.links);
      })
      .catch(console.log)
      .finally(() => setNotesLoading(false));
  };

  usePageTitle(title);

  useEffect(() => {
    getClient(params);
    getPinnedNotes(params);
  }, [params]);

  return (
    <DashboardLayout>
      {!isLoading && (
        <Page
          isStickyTitle
          title={
            <LeadPageHeader
              clientName={clientName}
              clientData={clientData}
              countries={countryListArr}
              updateCallback={() => getClient(params)}
              updateNotesCallback={() => getPinnedNotes(params)}
            />
          }
        >
          <>
            <LeadInfo
              lead={clientData}
              notesProps={{
                notes: pinnedNotesList,
                links,
                meta,
                isLoading: notesLoading,
                request: (params: { id: number }, isNextCall?: boolean) =>
                  getPinnedNotes(params, isNextCall),
              }}
              permissions={{
                showPersonalInfo: PermissionShowPersonalInfo,
                showContacts: PermissionShowContacts,
                showAddress: PermissionShowAddress,
              }}
            />
            <div className={styles.tabs}>
              <Tabs>
                <TabList>
                  <Tab>Profile</Tab>
                  <Tab>Notes</Tab>
                  {PermissionViewDocumentLogs ? <Tab>Feed</Tab> : null}
                </TabList>

                <TabPanel>
                  <div className={styles.tabContainer}>
                    {PermissionShowPersonalInfo ? (
                      <LeadPersonalInfoForm
                        formData={clientData}
                        updateComponent={() => getClient(params)}
                      />
                    ) : null}
                    {PermissionShowAddress ? (
                      <LeadAdressForm
                        formData={clientData}
                        countryList={countryListArr}
                        updateComponent={() => getClient(params)}
                      />
                    ) : null}
                    {PermissionShowContacts ? (
                      <LeadContactForm
                        formData={clientData}
                        updateComponent={() => getClient(params)}
                      />
                    ) : null}
                  </div>
                </TabPanel>
                <TabPanel>
                  <NotesTabs updatePinnedNotes={() => getPinnedNotes(params)} />
                </TabPanel>
                {PermissionViewDocumentLogs ? (
                  <TabPanel>
                    <Feed />
                  </TabPanel>
                ) : null}
              </Tabs>
            </div>
          </>
        </Page>
      )}
    </DashboardLayout>
  );
};

export default LeadPage;
