import { useEffect, useRef, useState } from 'react';
import { usePermission } from 'utils/usePermission';
import { Client } from 'models/Clients/Clients';
import cn from 'classnames';
import moment from 'moment';
import { Icon } from 'components/ui/Icon';
import UnblockedClientPopup from 'pages/Clients/Client/ClientPopups/UnblockedClientPopup';
import VerifyClientPopup from 'pages/Clients/Client/ClientPopups/VerifyClientPopup';
import ClientChangeAccountStatusPopup from 'pages/Clients/Client/ClientPopups/ClientChangeAccountStatusPopup';
import styles from './client-account-status.module.scss';

type Props = {
  client: Client;
  updateCallback: () => void;
};

const statusActionId = 'status-action';

export const ClientAccountStatus = ({ client, updateCallback }: Props) => {
  const { permissionGiven: PermissionEditStatus } = usePermission('admin.client.status');
  const { permissionGiven: PermissionEditUnblockedAccount } =
    usePermission('admin.client.unblocked');
  const { permissionGiven: PermissionEditBlockedAccount } = usePermission('admin.client.blocked');
  const { permissionGiven: PermissionVerifyAccount } = usePermission('admin.client.verified');

  const dropMenu = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const onClickOutside = (event) => {
      if (dropMenu.current) {
        if (!dropMenu.current.contains(event.target) || event.target.id === statusActionId) {
          setIsOpen(false);
        }
      }
    };

    document.addEventListener('click', onClickOutside, true);

    return () => document.removeEventListener('click', onClickOutside, true);
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={cn(styles.status, styles[client?.user_profile?.status])}>
          <span>{client && client?.user_profile?.status ? client.user_profile.status : '---'}</span>
          {PermissionEditStatus ? (
            <Icon
              name="caretDown"
              size={24}
              className={cn(isOpen && styles.active)}
              onClick={() => setIsOpen((o) => !o)}
            />
          ) : null}
        </div>
        {client?.updated_at && (
          <span>since {moment(client.updated_at * 1000).format('DD.MM.YYYY HH:mm:ss')}</span>
        )}
        <div ref={dropMenu} className={cn(styles.dropdown, isOpen && styles.dropdown__open)}>
          <ul>
            {client?.user_profile?.status === 'blocked' ? (
              PermissionEditUnblockedAccount ? (
                <UnblockedClientPopup
                  updateComponent={updateCallback}
                  userId={client.id}
                  triggerBtn={
                    <li id={statusActionId} className="unblock">
                      Unblocked
                    </li>
                  }
                />
              ) : null
            ) : (
              <>
                {client?.user_profile?.status !== 'verified' && PermissionVerifyAccount ? (
                  <VerifyClientPopup
                    updateComponent={updateCallback}
                    userId={client.id}
                    triggerBtn={
                      <li id={statusActionId} className="unblock">
                        Verify
                      </li>
                    }
                  />
                ) : null}
                {PermissionEditBlockedAccount ? (
                  <ClientChangeAccountStatusPopup
                    updateComponent={updateCallback}
                    userId={client.id}
                    triggerBtn={
                      <li id={statusActionId} className="blocked">
                        Blocked
                      </li>
                    }
                  />
                ) : null}
              </>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};
