import React, { FC, memo, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from '../clients-table.module.scss';
import { copyToBuffer } from 'utils/copyToBuffer';
import { Icon } from 'components/ui/Icon';

const cx = classNames.bind(styles);

interface IAffiliateProps {
  affiliate?: {
    first_name: string;
    last_name: string;
  };
  referral?: string;
  source?: string;
}

const Affiliate: FC<IAffiliateProps> = ({ affiliate, referral, source }) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isExpand, setIsExpand] = useState(false);
  const [showCaret, setShowCaret] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      setShowCaret(textRef.current.scrollHeight > textRef.current.clientHeight);
    }
  }, [affiliate]);

  const onToggle = () => setIsExpand((prev) => !prev);

  return (
    <>
      <div ref={textRef} className={cx('client__affiler', isExpand && 'client__affiler-full')}>
        {affiliate && (
          <span>
            <b>
              {affiliate.first_name} {affiliate?.last_name ? affiliate.last_name : ''}
            </b>
          </span>
        )}
        {affiliate && (
          <p
            className={cx('client__affiler-referrer')}
            onClick={() => copyToBuffer(referral, true)}
          >
            Referral: {referral || '---'}
          </p>
        )}
        {source && (
          <p className={cx('client__affiler-source')} onClick={() => copyToBuffer(source, true)}>
            Source: {source}
          </p>
        )}
        {!affiliate && !source && <span className={cx('client__affiler-empty')}>None</span>}
      </div>
      {showCaret && (
        <Icon
          className={cx('expandCaret')}
          name={isExpand ? 'caretUp' : 'caretDown'}
          size={16}
          onClick={onToggle}
        />
      )}
    </>
  );
};

export default memo(Affiliate);
