import { usePermission } from 'utils/usePermission';
import { deleteNote } from 'api/notes';
import { notify } from 'utils/notify';
import { Icon } from 'components/ui/Icon';
import EditNotePopup from '../EditNotePopup';

type Props = {
  note: any;
  updateNotesList: () => void;
};

export const NotesItemActions = ({ note, updateNotesList }: Props) => {
  const { permissionGiven: PermissionNotesDelete } = usePermission('admin.notes.delete');
  const { permissionGiven: PermissionNotesUpdate } = usePermission('admin.notes.update');

  const removeNote = () => {
    deleteNote(note?.id)
      .then((res) => {
        if (res.status === 200) {
          notify({
            type: 'success',
            message: 'Notes deleted successfully',
            timeOut: 3000,
          });
          updateNotesList();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      });
  };

  return (
    <>
      {PermissionNotesUpdate ? (
        <EditNotePopup
          type="edit"
          triggerBtn={<Icon name="notePencil" size={20} color="var(--main-color)" />}
          noteData={note}
          updateComponent={updateNotesList}
        />
      ) : null}

      {PermissionNotesDelete ? (
        <Icon name="trash" size={20} color="var(--red)" onClick={removeNote} />
      ) : null}
    </>
  );
};
