import { Spinner } from 'components/ui/Spinner';
import { LeadNote } from 'models/Leads/LeadNotes';
import { TableLinks, TableMeta } from 'models/Table';
import { PinnedNote } from 'components/ui/Notes';
import InfiniteScroll from 'react-infinite-scroll-component';

import styles from './lead-pinned-notes.module.scss';

type Props = {
  id: number;
  notes: LeadNote[];
  links: TableLinks;
  meta: TableMeta;
  isLoading: boolean;
  request: (params: { id: number }, isNextCall?: boolean) => void;
};

export const LeadPinnedNotes = ({ id, notes, links, meta, isLoading, request }: Props) => {
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div id="scrollableDiv" className={styles.container}>
      {notes.length > 0 && links && meta ? (
        <InfiniteScroll
          dataLength={notes.length}
          next={() => request({ id }, true)}
          hasMore={!!links.next}
          loader={
            <div style={{ position: 'relative', height: '50px' }}>
              <Spinner />
            </div>
          }
          height={290}
          scrollableTarget="scrollableDiv"
        >
          {notes.map((note) => (
            <PinnedNote key={note.id} note={note} />
          ))}
        </InfiniteScroll>
      ) : null}
    </div>
  );
};
