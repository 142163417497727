import { PinnedNote } from 'components/ui/Notes';

import styles from './client-pinned-notes.module.scss';

type Props = {
  notes: any[];
};

export const ClientPinnedNotes = ({ notes = [] }: Props) => {
  return (
    <div className={styles.container}>
      {notes
        .sort((a, b) => (a.create_at < b.create_at ? 1 : -1))
        .map((note) => (
          <PinnedNote key={note.id} note={note} />
        ))}
    </div>
  );
};
